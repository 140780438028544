@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    outline: none;
}

body {
    background-color: white;
}